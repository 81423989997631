
import { Box, Button, Typography, alpha } from '@mui/material';
import Markdown from 'markdown-to-jsx';
import { ElementLink } from 'modules/picasso-ui/link/LinkNormal';
import { orElse } from 'utils/objectUtils';
import { FormattedTypography } from '../../../picasso-ui/present/text/FormattedText';
import { FC, ReactNode } from 'react';
import { css } from "@emotion/react";
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

export interface LandingPageValuePropositionsMainSaasProps {
    items: {
        title?: string, 
        text?: string, 
        label?: string,
        secondary?: {
            content?: ReactNode | any
        }
    }[]
    id?: string
    showArrows?: boolean
    itemTitleProps?: any
    halfhalf?: boolean
    startRight?: boolean
    titleStyle?: any
    textStyle?: any
    marginBetween?: number
    alternate?: boolean
}

const styleDef = {
    root: theme=>({
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(3),
            paddingRight: theme.spacing(3),
        },
    }),
    rowStyle: theme=>css({
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.up('sm')]: {
            '&>*+*': {
                marginLeft: '64px',
            }
        },
    }),
    orientationRight: theme=>css({
        [theme.breakpoints.up('md')]: {
            flexDirection: 'row-reverse',
            '&>*+*': {
                marginLeft: '0px',
                marginRight: '64px',
            }
        }
    }),
    gridItem: theme=>css({
        display: 'flex', 
        flexDirection: 'column', 
        justifyContent: 'center',
        flexBasis: '50%',
        [theme.breakpoints.down('md')]: {
            marginBottom: theme.spacing(2),
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: theme.spacing(2) + 'px !important',
            paddingRight: theme.spacing(2) + 'px !important',
        },
        paddingTop: 0,
    }),
    primaryElemRoot: theme=>({
        //paddingLeft: theme.spacing(2),
        //paddingRight: theme.spacing(2),
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    }),
    label: theme=>({
        fontSize: '16px',
        color: theme.palette.primary.main,
        fontWeight: '600',
    }),
    textCont: theme=>({
        paddingRight: theme.spacing(3),
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        },
        fontSize: '18px',
        //lineHeight: '35px',
        color: theme.palette.text.primary,//'#43556d',//'#5b6169',//theme.palette.text.secondary,
        fontWeight: '400',
        whiteSpace: 'pre-wrap',
        '& ul': {
            marginLeft: '0',
            paddingLeft: '0px',
            paddingTop: theme.spacing(2),
        },
        '& li': {
            marginLeft: '0',
            marginBottom: '16px',
            listStyle: 'none',
            display: 'flex',
            '&::before': {
                content: '""',
                marginTop: '-3px',
                display: 'inline-block',
                height: '35px',
                width: '35px',
                minWidth: '35px',
                backgroundImage: 'url(/icons/check-simple-blue.svg)',
                backgroundSize: '15px 15px',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: 'center center',
                marginRight: '2px',
                //backgroundColor: '#eef6fd',
                borderRadius: '45px',
            }
        },
    }),
}

/**
 * 
 * left right switching
 * 
 * @param {*} param0 
 * @returns 
 */

export const LandingPageValuePropositionsMainSaas: FC<LandingPageValuePropositionsMainSaasProps> = ({items, id, showArrows, halfhalf, itemTitleProps, startRight, titleStyle, textStyle, marginBetween, alternate}) => {

    marginBetween = marginBetween || 22

    const renderItems = () => {



        return items.map((i, idx)=>{
            return <ItemElem 
                key={idx} 
                index={idx}
                title={i.title}
                text={i.text}
                label={i.label}
                secondary={i.secondary}
                itemsSize={items.length}
                showArrow={showArrows}
                halfhalf={halfhalf}
                itemTitleProps={itemTitleProps}
                startRight={startRight}
                titleStyle={titleStyle}
                textStyle={textStyle}
                marginBetween={marginBetween}
                alternate={alternate}
                />
        });
    }

    return (
        <div id={id} css={styleDef.root}>{renderItems()}</div>
    )

}

const ItemElem = ({title, text, index, secondary, itemsSize, showArrow, halfhalf, label, itemTitleProps, startRight, textStyle, titleStyle, marginBetween, alternate}) => {

    const isOrientationLeft = alternate===false || (startRight 
            ? index % 2 == 1
            : index % 2 == 0)

    const isLast = index === itemsSize - 1
    const isSecondLast = index === itemsSize - 2

    showArrow = orElse(showArrow, false)

    const primaryElem = <>
        <div css={styleDef.gridItem}>
            <PrimaryElem title={title} text={text} label={label} itemTitleProps={itemTitleProps} textStyle={textStyle} titleStyle={titleStyle} />
        </div>
    </>

    const hasSecondaryContent = secondary?.content

    const secondaryElem = hasSecondaryContent 
        ? <>
            <div css={styleDef.gridItem}>
                {secondary?.content}
            </div>
        </>
        :null

    const itemStyle = theme=>css({
        //justifyContent: !secondary?.content ? 'center':null,
        '&+&': {
            marginTop: theme.spacing(marginBetween),
        },
        paddingTop: '0'
    })

    let arrowRotate = null
    if (!hasSecondaryContent) {
        arrowRotate = isOrientationLeft
            ?  { transform: 'rotate(-45deg)' }
            :  { transform: 'rotate(45deg)' }
    }

    return (
        <div css={itemStyle}>
            <div css={[styleDef.rowStyle, isOrientationLeft ? null : styleDef.orientationRight]}>
                {primaryElem}
                {secondaryElem}
            </div>
            {showArrow === true && isLast === false && (
                <Box textAlign="center" pt={5} pb={5}>
                    <ArrowDownwardIcon sx={[theme=>({ color: '#eaeaea', fontSize: '50px'}), arrowRotate]} />
                    {/* <Image src="/icons/down-arrow.svg" width="50px" height="50px" css={arrowStyle} /> */}
                </Box>
            )}
        </div>
    )

}

const MdLink = ({children, ...props}) => {
    //@ts-ignore
    return <Box pt={4}><ElementLink {...props} prefetch={false}><Button variant="contained" color="primary" size="large" hoverInvert={true}>{children}</Button></ElementLink></Box>
}

const PrimaryElem = ({title, text, label, itemTitleProps, textStyle, titleStyle}) => {

    const overrides = {
        'a': MdLink
    }

    return (
        <Box sx={styleDef.primaryElemRoot}>
            {label && <Typography sx={styleDef.label} mb={0.8}>{label}</Typography>}
            <FormattedTypography
                component="h3"
                variant="h3"
                {...itemTitleProps} 
                sx={[(theme)=>({        
                    fontSize: '32px',
                    lineHeight: '1.3',
                    color: theme.palette.text.primary,
                }), itemTitleProps?.sx || null, titleStyle]}>
                {title}
            </FormattedTypography>
            <Box mt={4} sx={[styleDef.textCont, textStyle]}>
                {text && <Markdown options={{overrides}}>{text}</Markdown>}
            </Box>
        </Box>
    )

}